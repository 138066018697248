:root {
    --color-primary: #5267FE;
}

@font-face{
  font-family: "OCR-B";
  src: url("../public/OCR-B.ttf") format("truetype");
}

@font-face{
  font-family: "EPCOT";
  src: url("../public/epcot.ttf") format("truetype");
}

@font-face{
  font-family: "MOUSE";
  src: url("../public/mouse.ttf") format("truetype");
}

@font-face{
  font-family: "SAFARI";
  src: url("../public/static/safari.otf") format("truetype");
}

@font-face{
  font-family: "Anna ITC Std";
  src: url("../public/static/HS.otf") format("truetype");
}

@font-face{
  font-family: "CA";
  src: url("../public/static/Mostra_Nuova_Heavy.otf") format("truetype");
}

@font-face{
  font-family: "IMPACT";
  src: url("../public/static/impact.ttf") format("truetype");
}

html {
    font-size: 62.5%;
    scroll-behavior: smooth;
}

@media (max-width: 768px) {
    html {
        font-size: 56% !important;
    }
}

html,
body {
    padding: 0;
    margin: 0;
    /*font-family: "Lato", sans-serif;*/
    font-family: "OCR-B", sans-serif !important;
    font-weight: 400 !important;
    color: #171717;
    max-width: 100vw !important;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
}

a {
    color: inherit;
    text-decoration: none;
}

* {
    box-sizing: border-box;
    scroll-behavior: smooth;
}

input:focus,
textarea:focus,
select:focus,
button:focus {
    outline: none;
}

.range {
    -webkit-appearance: none;
    width: 100%;
    height: 15px;
    border-radius: 5px;
    background: #d3d3d3;
    outline: none;
    opacity: 0.7;
    -webkit-transition: .2s;
    transition: opacity .2s;
}

.range::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  background: blue;
  cursor: pointer;
}
